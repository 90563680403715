// Header.js
import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Select from 'react-select';
import matchSorter from 'match-sorter'
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Modal from 'react-modal';
import 'react-dropdown/style.css'
import LocationPrintLabel from './LocationPrintLabel';
import ReactToPrint from "react-to-print";
import moment from 'moment';
import { CSVLink } from "react-csv";

const printModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};
class ProductBinDetail extends Component {

    constructor(props) {
        super(props);        

        this.state = {
            upc_search: '',
            quick_bin: '',
            sku_search: typeof(localStorage.getItem('skuSearch')) === 'undefined' ? '' : localStorage.getItem('skuSearch'),
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            cl_name: '',                       
            pr_id: '', 
            pr_qty: 0,
            bn_bin: '',
            bn_qty: 0,
            bn_order: 0,
            chk_bn_priority: true, 
            bn_last_updated: moment().format("YYYY-MM-DD HH:mm:ss"),       
            productbins: [],
            retiredbins: [],
            products: [],
            orders: [],
            selectedValue: '',
            selectedLabel: '',
            pr_qty_ava: 0,
            pr_qty_res: 0,
            bn_qty_total: 0,
            refresh: 0,
            res: '',
            update_message: '',
            binMessage: '',
            printLocation: '',
            printQty: 1,
            printSize: 48,
            labelWidth: 6,
            labelHeight: 200,
            labelModalIsOpen: false,
            trojanSerial:'',
            userToken: localStorage.getItem('userToken')
        };
               
       
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleQtyInputChange = this.handleQtyInputChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.getProductBins = this.getProductBins.bind(this);
        this.getRetiredBins = this.getRetiredBins.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.onActivateItem = this.onActivateItem.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleBinPress = this.handleBinPress.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleQOHChange = this.handleQOHChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.findUPC = this.findUPC.bind(this);
        this.binUPC = this.binUPC.bind(this);
        this.handleBinChange = this.handleBinChange.bind(this);
        this.findTrojanSerial = this.findTrojanSerial.bind(this);
        this.parseTrojanUPC = this.parseTrojanUPC.bind(this);

        this.openLabelModal = this.openLabelModal.bind(this);
        this.afterOpenLabelModal = this.afterOpenLabelModal.bind(this);
        this.closeLabelModal = this.closeLabelModal.bind(this);
        this.playGood = this.playGood.bind(this);
        this.playBad = this.playBad.bind(this);
        
    }

    componentWillMount() {      
      //console.log('component will mount');

    }

    componentDidMount() {      
      Modal.setAppElement('#main');
      this.handleClientChange(this.state.cl_id);
      //console.log('refresh: ' + this.state.refresh);
    }
   
    componentDidUpdate(prevProps, prevState) {
      //console.log('component did update');
      //console.log('refresh from update: ' + this.state.refresh);
      //console.log('resp: ' + this.state.resp);
    }

    playGood() {
      const audioEl = document.getElementsByClassName("good-audio-element")[0]
      audioEl.play()
    }
  
    playBad() {
      const audioEl = document.getElementsByClassName("bad-audio-element")[0]
      audioEl.play()
    }
    
    //LABEL
    openLabelModal = (bin) => {
     
      this.setState({labelModalIsOpen: true});     
      this.setState({printLocation: bin});

    }

    afterOpenLabelModal = (e) => {

    }

    closeLabelModal() {
      this.setState({labelModalIsOpen: false});
      this.setState({printQty:1});
    } 
      // EVENT HANDLERS

    parseTrojanUPC = () => { 
  
      let barcode = this.state.upc_search.trim(); 
      var regex = /(?<=\-)(.*?)(?=\+)/;
      //var strToMatch = "180/72K/41-2101007ST+JD201005-2";
      var otherSku = barcode.match(regex);
      console.log('matched: ' + otherSku[0]);
      
      //return true;
      var otherJSON = JSON.stringify({
          cl_id: this.state.cl_id,
          ot_sku: otherSku[0]            
      });

      console.log('getSku: ' + otherJSON)

      return fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/other/sku/'+this.state.cl_id, {
        method: 'post',
        body: otherJSON,      
        headers: {            
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken          
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
      }).then(res => res.json())
      .then(trojanSerial => this.setState({ trojanSerial }))
      .then(this.findTrojanSerial).catch(function(error) {
        alert(error);      
      });
      
    } 

    findTrojanSerial = () => {
      (async () => {
        let _product = this.state.trojanSerial;
        this.setState({upc_search:_product[0].pr_id})
        await this.findUPC();
      })();   
    }
  
    binTrojanSerial = () => {
      (async () => {
        let _product = this.state.trojanSerial;
        this.setState({quick_bin:_product[0].pr_id})
        this.setState({pr_id:_product[0].pr_id})
        await this.binUPC();
      })();   
    }
  
    getProducts = (url) => {
        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(products => this.setState({ products }));      
    }

    getProductBins = (url) => {
        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(productbins => this.setState({ productbins }));
    }

    getRetiredBins = (url) => {
        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(retiredbins => this.setState({ retiredbins }));
    }

    getOrders = (prid) => {
      var url = process.env.REACT_APP_NODE_ROOT_URL+'/orders/o/location/'+prid;

      var productJSON = JSON.stringify({
        cl_id: this.state.cl_id,
        pr_id: prid
      });

      console.log('logging product json...');
      console.log(productJSON);      
      
      fetch(url, {
            method: 'post',        
            body: productJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(orders => this.setState({ orders }));       
      
    }

    handleClientChange = (cl_id) => {       
        
        //3. - get product list
        if (cl_id === 0) {
            this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products');
        } else {
            this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products/client/'+cl_id);            
        }

         
    }    
   
    handleProductChange = (e) => {

      console.log(e);
      console.log(e.pr_id)
                  
      var qtyoh = 0;
      if (typeof e.pr_qty !== "undefined") {
        qtyoh = e.pr_qty;
      }

      var qtyres = 0;
      if (typeof e.pr_qty_reserved !== "undefined") {
        qtyres = e.pr_qty_reserved;
      } 
      
      var qtyava = 0;
      if (typeof e.pr_qty_avail !== "undefined") {
        qtyava = e.pr_qty_avail;
      } 
    
      if (e.pr_id !=='') {
        //console.log('updating values');
        this.setState({selectedValue: e.pr_id});
        this.setState({selectedLabel: e.pr_desc});
        this.setState({pr_id: e.pr_id});
        this.setState({pr_qty: qtyoh });
        this.setState({pr_qty_res: qtyres});
        this.setState({pr_qty_ava: qtyava});
        this.setState({cl_id: e.cl_id});

        this.getProductBins(process.env.REACT_APP_NODE_ROOT_URL+'/products/bins/'+e.pr_id);  
        this.getRetiredBins(process.env.REACT_APP_NODE_ROOT_URL+'/products/retiredbins/'+e.pr_id);
        this.getOrders(e.pr_id);

      }      

    } 

    //TODO: need to add user and timestamps to all updates
    handleQOHChange = (e) => {     
      
      e.preventDefault();

      const target = e.target;
      const value = target.value;
      var qtyres = this.state.pr_qty_res;

      var qtyava = 0;
      qtyava = value-qtyres;

      //console.log('set pr_qty value: ' + value);
      this.setState({pr_qty: value}); 
      this.setState({pr_qty_ava: qtyava});
     
    } 

    handleKeyUp = (e) => {  

      //if (e.keyCode === 13) {

        e.preventDefault();
        //const target = e.target;
        const value = this.state.pr_qty; //target.value;

        //console.log('value to submit: ' + value);

        var productJSON = JSON.stringify({
          pr_qty: value
        });

        //console.log('logging product json...');
        //console.log(productJSON);      
        
        fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/qty/'+this.state.pr_id, {
              method: 'post',        
              body: productJSON,      
              headers: {            
              'Content-Type': 'application/json',
              'bwToken': this.state.userToken
          }
              //TODO:
              //credentials: 'same-origin', // send cookies
              //credentials: 'include'   // send cookies, even in CORS
          
          }).then(function(response) {
              alert('Quantity on Hand updated successfully');                 
              //console.log(response);
              
          }).then(function(data) { 
              //onsole.log('update bin data on page...');                                 
          }).catch(function(error) {
              alert('Quantity on Hand could not be updated'); 
              //console.log('submit err: ' + error);       
          });
          

      //}
    }

    checkBin = (event) => {
      event.preventDefault();
      var hasDupe;
      this.state.productbins.map((bin, idx) => { 
          if (bin.bn_bin===this.state.bn_bin) {
            hasDupe=true;
            alert('Location already exists for this item. Please update the existing location quantity');               
          }
          return true;
      });
      
      if (hasDupe) {
        return false;
      } else {
        this.handleSubmit();
      }
    }

    handleSubmit = () => {
        
      var binJSON = JSON.stringify({
            cl_id: this.state.cl_id,           
            pr_id: this.state.pr_id,
            bn_bin: this.state.bn_bin,
            bn_qty: this.state.bn_qty,
            bn_last_updated: this.state.bn_last_updated,
            priority: this.state.chk_bn_priority===true ? 1 : 0            
          });

      console.log('logging location json...');
      console.log(binJSON);
          
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/bins/'+this.state.pr_id, {
            method: 'post',        
            body: binJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(res => res.json())
        .then(bin => this.playGood())
        .catch(function(error) {
          alert('Product location could not be added'); 
          console.log('submit err: ' + error);       
        /*
        
        .then(function(response) {                        
            console.log('resp: ' + JSON.stringify(response));
            //window.location.reload();
            //this.setState({refresh:1});            
        }).then(function(data) { 
            //console.log('update bin data on page...');
            console.log('data: ' + JSON.stringify(data));
        }).catch(function(error) {
            alert('Product location could not be added'); 
            console.log('submit err: ' + error);       
        */
        
          });
        
          this.setState({bn_bin: ''});
          this.setState({bn_qty: 0});    
        
    }    

    handleUpdate = (event) => {
      event.preventDefault();
      //bn_id`,bn_bin`,bn_qty`,bn_last_updated`,cl_id`,pr_id
      
      //var binJSON = JSON.stringify({
      //      cl_id: this.state.cl_id,           
      //      pr_id: this.state.pr_id,
      //      productbins: this.state.productbins});

      //      console.log('logging bin update json...');
      
      //      console.log(binJSON);

          this.state.productbins.map((bin, idx) => { 

            var _binId = bin.bn_id;
            var binJSON = JSON.stringify({
              bn_qty: bin.bn_qty,           
              bn_order: bin.bn_order,
              bn_last_updated: this.state.bn_last_updated});

            //console.log(_binId);
            //console.log(binJSON);

            fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/bins/upd/'+_binId, {
              method: 'post',        
              body: binJSON,      
              headers: {            
              'Content-Type': 'application/json',
              'bwToken': this.state.userToken        
              }
              //TODO:
              //credentials: 'same-origin', // send cookies
              //credentials: 'include'   // send cookies, even in CORS
          
            }).then(res => res.json())
            .then(this.setState({update_message: 'Locations updated successfully'}))
            .catch(function(error) {
              //alert('Product Bin could not be retired'); 
              console.log('submit err: ' + error);       
            });                    
            
            return true;

          });

    }

    handleQtyInputChange(event,i) {

      const target = event.target;
      const value = target.value;

      let {productbins} = this.state;  

      productbins[i].bn_qty = Number(value);
      
      this.setState({productbins});

    }

    handlePriorityChange(event,i) {

      const target = event.target;
      const value = target.value;

      let {productbins} = this.state;  

      productbins[i].bn_order = Number(value);
      
      this.setState({productbins});

    }

    handleInputChange(event) {
   
      const target = event.target;
      const value = target.name === 'chk_bn_priority' ? target.checked : target.value;
      const name = target.name;

      this.setState({update_message: ''});
      
      this.setState({
       [name]: value
      });      
    }

    handleChange(event){
      
      event.preventDefault();
      localStorage.setItem('upc_search', event.target.value);
      this.setState({upc_search: event.target.value});
     
    };

    handleBinChange(event){
      
      event.preventDefault();
      localStorage.setItem('quick_bin', event.target.value);
      this.setState({quick_bin: event.target.value});
     
    };
  
    handleBinPress = (event) => {
      
      if(event.key === 'Enter'){

          event.preventDefault();
          //set upc from search box
                  
          console.log('scanning new upc - play sound');
        /* OLD TROJAN CODE
          if (this.state.cl_id == '22') {
            let barcode = this.state.quick_bin;
            console.log(barcode); 

            if (barcode.indexOf('+') > 0) {
              var regex = /(?<=\-)(.*?)(?=\+)/;
              //var strToMatch = "180/72K/41-2101007ST+JD201005-2";
              var otherSku = barcode.match(regex);
              console.log('matched: ' + otherSku[0]);
              
              //return true;
              var otherJSON = JSON.stringify({
                  cl_id: this.state.cl_id,
                  ot_sku: otherSku[0]            
              });
        
              console.log('getSku: ' + otherJSON)
        
              return fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/other/sku/'+this.state.cl_id, {
                method: 'post',
                body: otherJSON,      
                headers: {            
                'Content-Type': 'application/json',
                'bwToken': this.state.userToken          
                }
                //TODO:
                //credentials: 'same-origin', // send cookies
                //credentials: 'include'   // send cookies, even in CORS
            
              }).then(res => res.json())
              .then(trojanSerial => this.setState({ trojanSerial }))
              .then(this.binTrojanSerial).catch(function(error) {
                alert(error);      
              });          
            } else {
              this.binUPC();
            }            
          
        } else {
*/
          this.binUPC()
        
       // }
                     
      }
    }
    
    handleKeyPress = (event) => {
      
      if(event.key === 'Enter'){

          event.preventDefault();
          //set upc from search box
                  
          console.log('scanning new upc - play sound');
          this.findUPC();       
      }
    }

    findUPC = () => {
      
      const _findupc = this.state.upc_search.trim(); //this.state.upc_search.trim().replace(/^0+/, '');
      
      const _prid = this.state.pr_id;

      var loc = _findupc.indexOf("-")

      console.log('upc: ' + _findupc)
      console.log('loc: ' + loc)
      
      if (loc === -1) {
        const result = this.state.products.find( product => product.pr_upc === _findupc );

        //if found, then log scan
        if (result) {
          console.log('result: ' + JSON.stringify(result));
          this.handleProductChange(result);
        }
      } else {

        console.log('quick bin it!')
        if (_prid === '') {
          console.log('find all products for a location instead')

          //load up product selector with items we find. 
          var binJSON = JSON.stringify({           
            bn_bin: _findupc
          });
  
          console.log('logging find products by bin json..');
          console.log(binJSON);
                  
          fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/allbins/'+this.state.cl_id, {
                method: 'post',        
                body: binJSON,      
                headers: {            
                'Content-Type': 'application/json',
                'bwToken': this.state.userToken
            }
                //TODO:
                //credentials: 'same-origin', // send cookies
                //credentials: 'include'   // send cookies, even in CORS
            
            }).then(res => res.json())
            .then(products => this.setState({ products })).then(function(response) {
                //this.playBad();
                //alert('Other products have been found for this location. See select box for results.');                 
                console.log('resp: ' + JSON.stringify(response));
                //window.location.reload();
                //this.setState({refresh:1});            
            }).then(function(data) { 
                //console.log('update bin data on page...');
                console.log('data: ' + JSON.stringify(data));
            }).catch(function(error) {
                alert('Product location could not be added'); 
                console.log('submit err: ' + error);       
            });

        } else {
          binJSON = JSON.stringify({
            cl_id: this.state.cl_id,           
            pr_id: this.state.pr_id,
            bn_bin: _findupc,
            bn_qty: 1,
            bn_last_updated: this.state.bn_last_updated,
            priority: 0            
          });
  
          console.log('logging location json from find upc...');
          console.log(binJSON);
                  
          fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/bins/'+this.state.pr_id, {
                method: 'post',        
                body: binJSON,      
                headers: {            
                'Content-Type': 'application/json',
                'bwToken': this.state.userToken
            }
                //TODO:
                //credentials: 'same-origin', // send cookies
                //credentials: 'include'   // send cookies, even in CORS
            
            }).then(res => res.json())
            .then(bin => this.playGood())            
            .catch(function(error) {
              alert('Product location could not be added'); 
              console.log('submit err: ' + error);       
          });

          this.setState({bn_bin: ''});
          this.setState({bn_qty: 0});    
            
            /*
            .then(function(response) {                
                console.log('resp: ' + JSON.stringify(response));
                //window.location.reload();
                //this.setState({refresh:1});            
            }).then(function(data) { 
                //console.log('update bin data on page...');
                console.log('data: ' + JSON.stringify(data));
            }).then(this.getProductBins(this.state.pr_id)).catch(function(error) {
                alert('Product location could not be added'); 
                console.log('submit err: ' + error);       
            });
*/
        }       
         

      }


      this.setState({upc_search: ''});
      

      //cut here
      return true;
    }

    parseTrojanUPC = (barcode) => { 
   
      var regex = /(?<=\-)(.*?)(?=\+)/;
      //var strToMatch = "180/72K/41-2101007ST+JD201005-2";
      var otherSku = barcode.match(regex);
      console.log('matched: ' + otherSku[0]);
      
      //return true;
      var otherJSON = JSON.stringify({
          cl_id: this.state.cl_id,
          ot_sku: otherSku[0]            
      });

      console.log('getSku: ' + otherJSON)

      return fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/other/sku/'+this.state.cl_id, {
        method: 'post',
        body: otherJSON,      
        headers: {            
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken          
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
      }).then(res => res.json())
      .then(trojanSerial => this.setState({ trojanSerial }))
      .then(this.findTrojanSerial)
      .catch(function(error) {
        alert(error);      
      });
    
    }
   
    binUPC = () => {
      let _findupc  = '';
      var loc=0;

      /* OLD TROJAN CODE
      if (this.state.cl_id==22) {
        
        if (typeof(this.state.quick_bin) == 'number') {
          console.log('isnum');
          _findupc = this.state.quick_bin;
          console.log('upc: ' + _findupc);
          loc = -1;
          
        } else {
          _findupc = this.state.quick_bin;
          loc = _findupc.indexOf("-");     
          console.log('upc else: ' + _findupc);     
        }       
        
      } else {
        */
        _findupc = this.state.quick_bin;
        loc = _findupc.indexOf("-");
      //}
             
      console.log('loc: ' + loc);

      let result;
      
      if (loc === -1) {
        /*
        OLD TROJAN CODE
        if (this.state.cl_id==22) {
          result = this.state.products.find( product => product.pr_id === _findupc );
        } else {
          */
          result = this.state.products.find( product => product.pr_upc === _findupc );
        //}        

        if (result) {
          this.setState({pr_id: result.pr_id});
          this.setState({quick_bin: ''});
          this.setState({binMessage:'UPC found, scan location'})
        } else {
          this.playBad();
          this.setState({binMessage:'UPC not found'})
          return false;
        }
      } else {

        console.log('quick bin it!')
        this.setState({binMessage:''})
        
          var binJSON = JSON.stringify({
            cl_id: this.state.cl_id,           
            pr_id: this.state.pr_id,
            bn_bin: _findupc,
            bn_qty: 1,
            bn_last_updated: this.state.bn_last_updated,
            priority: 0            
          });
  
          console.log('logging location json from find upc...');
          console.log(binJSON);
                  
          fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/bins/'+this.state.pr_id, {
                method: 'post',        
                body: binJSON,      
                headers: {            
                'Content-Type': 'application/json',
                'bwToken': this.state.userToken
            }
                //TODO:
                //credentials: 'same-origin', // send cookies
                //credentials: 'include'   // send cookies, even in CORS
            
            }).then(res => res.json())
            .then(this.setState({binMessage:'success'}))
            .then(this.setState({quick_bin: ''}))
            .then(bin => this.playGood())
                        
            .catch(function(error) {
              alert('Product location could not be added'); 
              console.log('submit err: ' + error);       
          });

          this.setState({bn_bin: ''});
          this.setState({bn_qty: 0});    
            
            /*
            .then(function(response) {                
                console.log('resp: ' + JSON.stringify(response));
                //window.location.reload();
                //this.setState({refresh:1});            
            }).then(function(data) { 
                //console.log('update bin data on page...');
                console.log('data: ' + JSON.stringify(data));
            }).then(this.getProductBins(this.state.pr_id)).catch(function(error) {
                alert('Product location could not be added'); 
                console.log('submit err: ' + error);       
            });
*/
             
         

      }


      this.setState({upc_search: ''});
      

      //cut here
      return true;
    }

    //TODO - write activate routes/handlers
    onActivateItem = (event,i) => {      
      event.preventDefault();

      const target = event.target;
      const value = target.value; 

      var activateJSON = JSON.stringify({
        cl_id: this.state.cl_id,        
        bn_retired: '0',        
        bn_last_updated: this.state.bn_last_updated
      }); 

      console.log('logging bin activate json...');
      console.log(activateJSON);      
        
      const res = this.state.retiredbins[i];

      const productbins = this.state.productbins;
      productbins.push(res);
      this.setState({productbins});
            
      this.setState(state => {
        const retiredbins = state.retiredbins.filter((item, j) => i !== j);
          return {
            retiredbins,
          };
      });
              

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/bins/updr/'+value, {
          method: 'post',        
          body: activateJSON,      
          headers: {            
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken        
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {
          console.log('Location activated successfully');                 
          console.log(JSON.stringify(response));
      }).then(function(data) { 
          //this.getProductBins('http://54.244.206.141:3001/products/bins/'+this.state.pr_id);  
          //this.getRetiredBins('http://54.244.206.141:3001/products/retiredbins/'+this.state.pr_id);             
      }).catch(function(error) {
          alert('Product Location could not be activated'); 
          console.log('submit err: ' + error);       
      });
      

    }

    onRemoveItem = (event,i) => {      
      event.preventDefault();
      const target = event.target;
      const value = target.value;        
      
      console.log('look here')  
      console.log(JSON.stringify(this.state.productbins[1]));

      const res = this.state.productbins[i];


      var removeJSON = JSON.stringify({
        cl_id: this.state.cl_id,        
        bn_retired: 1,        
        bn_last_updated: this.state.bn_last_updated
      });       

      const retiredbins = this.state.retiredbins;
      retiredbins.push(res);
      this.setState({retiredbins});

      this.setState(state => {
        const productbins = state.productbins.filter((item, j) => i !== j);
          return {
            productbins,
          };
      });

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/bins/updr/'+value, {
          method: 'post',        
          body: removeJSON,      
          headers: {            
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken        
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {
          console.log('Location retired successfully');                 
          //console.log(response);
      }).then(function(data) { 
          console.log('updated the location list..');            

          //console.log(data);            
      }).catch(function(error) {
          alert('Product location could not be retired'); 
          console.log('submit err: ' + error);       
      });

      
    }
   
    render(){
      
      const mappingFunction = p => ({ label: p.pr_sku + ' - ' + p.pr_desc + ' (' + p.pr_upc + ')', value: p.pr_id, pr_qty: p.pr_qty, pr_qty_reserved: p.pr_qty_reserved, pr_qty_avail: p.pr_qty_avail, pr_id: p.pr_id, cl_id: p.cl_id, pr_desc: p.pr_desc });

      //product drop down
      const productsearch = this.state.products.map(mappingFunction);
            
      const { orders } = this.state;

      let totalBinQty = 0;

      this.state.productbins.map((bin, idx) => { 
         totalBinQty += this.state.productbins[idx].bn_qty;
         return true;
      });

        return (
          <div id="main">
            <div className="page-wrapper">
              <div className="row page-titles">
                <div className="col-md-12 align-self-center">
                  <h3 className="text-primary">Product Locations</h3> 
                </div>
               
              </div>
              <div className="container-fluid">         
              <div className="card">
              <div className="card-body" name="card-order-list">      
                <div className="row">  
                  <div className="col-md-6">
                  <h4>Quick Search and Put Away</h4>
                  <p>Two options:<br/>
                  1. Scan a UPC, then scan a Location to put away qty 1 at that location (quick bin)</p>
                  <form id="formQuickBin">
                    <input type="text" id="quick_bin" autoFocus ref={(input) => { this.quick_bin = input; }} name="quick_bin" onKeyPress={this.handleBinPress} value={this.state.quick_bin} onChange={this.handleBinChange} placeholder="Place cursor here to quick bin an item"/>
                  </form>
                  <div className="binMessage">{this.state.binMessage}</div>
                  <br/>
                  <p>
                  2. Scan a Location first. All products with that Location will load in the select box below</p>

                  <form id="formUpcSearch">
                    <input type="text" id="upc_search" ref={(input) => { this.upc_search = input; }} name="upc_search" onKeyPress={this.handleKeyPress} value={this.state.upc_search} onChange={this.handleChange} placeholder="Place cursor here search by UPC"/>
                  </form>
                    <br/><br/>
                    <h4>Search by SKU, Name or UPC </h4>
                    <Select 
                      options={productsearch} 
                      className="product-drop" 
                      name="product-search" 
                      onChange={this.handleProductChange} 
                      value={productsearch.filter(({value}) => value === this.state.selectedValue)}
                      placeholder="Search products"
                       />
                  </div>
                </div>
                
                <div className="row">  
                  <div className="col-md-9">
                    <div id="div-product-bins" className="form-group">           
                        <h3>{this.state.selectedLabel}</h3>                        
                        <h4 data-toggle="tooltip" title="This is the CURRENT PHYSICAL QTY IN WAREHOUSE">Quantity Available: {this.state.pr_qty}</h4>                      
                        <h4 data-toggle="tooltip" title="This is the QTY RESERVED FOR ORDERS NOT SHIPPED">Quantity Reserved: {this.state.pr_qty_res}</h4>                        
                        <h4 data-toggle="tooltip" title="This is the TOTAL LOCATION QTY FOUND">Total Location Qty: { totalBinQty }</h4>
                        <h4 data-toggle="tooltip" title="This is the QUANTITY ON HAND MINUS the TOTAL LOCATION QTY">Total NOT Put Away: {this.state.pr_qty-totalBinQty}</h4>
                        <br/>
     <hr/>
     <br/>
     
                      <div className="row">
                      <div className="col-md-9">  
                        <div className="form-group">
                        <form onSubmit={this.checkBin}>
                         <h3>Add a new location</h3>
                         <label>
                            Enter a QTY and then scan location:<br/>
                            <input
                              name="bn_qty"
                              type="text"
                              value={this.state.bn_qty}
                              onChange={this.handleInputChange} className="qoh-field" /> 
                          </label> <br/>
                          <label>
                            Location:<br/>
                            <input
                              name="bn_bin"
                              type="text"
                              value={this.state.bn_bin}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          

                          <label><input name="chk_bn_priority" type="checkbox" checked={this.state.chk_bn_priority} onChange={this.handleInputChange} /> Set as Priority Location</label>
                          <br />
                          <input type="submit" value="Add Location" className="btn btn-primary btn-sm"/>
                          </form>
                        </div>
                      </div>  
                      </div>
                      
                      <h3>Current Locations &nbsp; <button 
                              className="btn refresh" 
                              onClick={() => this.getProductBins(process.env.REACT_APP_NODE_ROOT_URL+'/products/bins/'+this.state.pr_id)}>refresh</button>
                      </h3>
                      <span style={{ color: "green"}}>{this.state.update_message}</span>
                      <form onSubmit={this.handleUpdate}>
                          <table className="table-product-bins" cellPadding="10px" cellSpacing="10px">
                            <thead> 
                              <tr>
                                <td className="td-bn-label">Label</td>
                                <td className="td-bn-location">Location</td>
                                <td className="td-bn-id">&nbsp;</td>
                                <td className="td-bn-qty">Qty</td>
                                <td className="td-bn-id">&nbsp;</td>
                                <td className="td-bn-lastupdated">Date Added</td>
                                <td className="td-bn-id">&nbsp;</td>
                                <td className="td-bn-rem">Location Priority</td>
                                <td className="td-bn-id">&nbsp;</td>
                                <td className="td-bn-rem">Retire</td>                              
                              </tr>
                            </thead>
                            {this.state.productbins.map((bin, idx) => {
                    
                              let tblId = `tbl-${idx}`;
                              let nameId = `item-${idx}`;
                              let priorityId = `priority-${idx}`;                              

                              return (
                                  <tbody key={tblId}>
                                  <tr>
                                    <td><a href="#main" onClick={(e) => this.openLabelModal(this.state.productbins[idx].bn_bin)} title="print labels">print</a></td>
                                    <td className="td-oi-label">{this.state.productbins[idx].bn_bin}</td>
                                    <td className="td-bn-id">&nbsp;</td>
                                    <td className="td-oi-qty"><input name={nameId} 
                                      type="text" 
                                      value={this.state.productbins[idx].bn_qty || 0} 
                                      onChange={(e) => {this.handleQtyInputChange(e, idx)}}                                      
                                      className="qty_field" />                                      
                                    </td>
                                    <td className="td-bn-id">&nbsp;</td>
                                    <td className="td-oi-dt">{moment(this.state.productbins[idx].bn_last_updated).format("MM-DD-YYYY hh:mm:ss A")}</td>
                                    <td className="td-bn-id">&nbsp;</td>
                                    <td className="td-bn-id">
                                        <input name={priorityId} 
                                          type="text" 
                                          value={this.state.productbins[idx].bn_order || 1} 
                                          onChange={(e) => {this.handlePriorityChange(e, idx)}}                                      
                                          className="priority_field" />
                                    </td>
                                    <td className="td-bn-id">&nbsp;</td>
                                    <td>
                                      <button
                                        type="button"
                                        value={this.state.productbins[idx].bn_id}
                                        onClick={(e) => {this.onRemoveItem(e, idx)}}
                                      >X</button>
                                    </td>
                                  </tr>
                                  <tr><td colSpan="9">
                                 
                                  </td></tr>                       
                                  </tbody>  
                                                           
                                )
                              })                
                            }                          
                          </table>                          
                          <div>

                            <input type="submit" value="Update Locations" className="btn btn-primary btn-sm"/>&nbsp;                        
                          </div>
                          <br/><hr/><br/>

                      

<br/><br/>

                      <h3>Retired Locations &nbsp; <button 
                              className="btn refresh"                               
                              onClick={() => this.getRetiredBins(process.env.REACT_APP_NODE_ROOT_URL+'/products/retiredbins/'+this.state.pr_id)}>refresh</button>
                      </h3>
                          <table className="table-product-bins" cellPadding="10px" cellSpacing="10px">
                            <thead>
                              <tr>
                                <td className="td-bn-label">Location</td>
                                <td className="td-bn-id">&nbsp;</td>
                                <td className="td-bn-qty">Qty</td>
                                <td className="td-bn-id">&nbsp;</td>
                                <td className="td-bn-lastupdated">Date Added</td>
                                <td className="td-bn-id">&nbsp;</td>
                                <td className="td-bn-rem">Location Priority</td>
                                <td className="td-bn-id">&nbsp;</td>
                                <td className="td-bn-rem">Activate</td>                              
                              </tr>
                            </thead>
                            {this.state.retiredbins.map((bin, idx) => {
              
                              let tblId = `tbl-${idx}`;                                     

                              return (
                                  <tbody key={tblId}>
                                  <tr>                                    
                                    <td className="td-oi-label">{this.state.retiredbins[idx].bn_bin}</td>
                                    <td className="td-bn-id">&nbsp;</td>
                                    <td className="td-oi-qty">{this.state.retiredbins[idx].bn_qty || 0}
                                    </td>
                                    <td className="td-bn-id">&nbsp;</td>
                                    <td className="td-oi-dt">{moment(this.state.retiredbins[idx].bn_last_updated).format("MM-DD-YYYY hh:mm:ss A")}</td>
                                    <td className="td-bn-id">&nbsp;</td>
                                    <td className="td-bn-id">
                                        {this.state.retiredbins[idx].bn_order || 1}
                                    </td>
                                    <td className="td-bn-id">&nbsp;</td>
                                    <td>
                                      <button
                                        type="button"
                                        value={this.state.retiredbins[idx].bn_id}
                                        onClick={(e) => {this.onActivateItem(e, idx)}}
                                      >Y</button>
                                    </td>
                                  </tr>
                                  <tr><td colSpan="9">
                                 
                                  </td></tr>                       
                                  </tbody>  
                                                           
                                )
                              })                
                            }                          
                          </table>
</form>

                      </div>                     
                      
                      </div>
                      </div>
                      <br/>
                      <br/>                      
                      
                      <h3>Unshipped Orders</h3>
                      <CSVLink data={orders}>Download CSV of Related Orders</CSVLink>
                      <div className="table-responsive">
                                   
                                     <ReactTable
                                      data={orders}
                                      filterable
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value} 
                                      columns={[
                                        {                                          
                                          columns: [                                            
                                            {
                                              Header: "cl_id",
                                              accessor: "cl_id",
                                              show: false
                                            },
                                            {
                                              Header: "or_id",
                                              accessor: "or_id",
                                              show: false
                                            },
                                            {
                                              Header: "Order ID",
                                              accessor: "or_actual_order_id",
                                              width: 100,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_actual_order_id"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Order Date",
                                              accessor: "or_date",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },        
                                            {
                                              Header: "Status",
                                              accessor: "or_status",
                                              width: 100,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value) &&
                                                row[filter.id].endsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Total Items",
                                              accessor: "total_items",
                                              width: 100,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["total_items"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            }                                       
                                          ]
                                        }
                                      ]}
                                      defaultSorted={[
                                        {
                                          id: "or_id",
                                          desc: true
                                        }
                                      ]}
                                      defaultPageSize={50}
                                      className="-striped -highlight"
                                      getTdProps={(state, rowInfo, column, instance) => {
                                        return {
                                          onClick: (e) => {
                                            window.open("/order/"+rowInfo.row.cl_id+"/"+rowInfo.row.or_id, "_new");
                                           
                                          }
                                        };
                                      }}
                                    />

                                   


                                </div>

                    </div>
               </div>
               </div>
              </div>
              <div id="modalMain">
              <Modal
                            isOpen={this.state.labelModalIsOpen}
                            onAfterOpen={this.afterOpenLabelModal}                            
                            style={printModalStyle}
                            contentLabel="Print Label"
                          >                   
                         <div className="frame">
													    <div className="scroll"> 
                            <div className="container-fluid">               
                              <form onSubmit={this.handleLabelPrint}>
                                  <div className="row">
                                      <div className="col-md-6">            
																				<h3>Print Location Labels</h3>
                                        <div className="form-group" >
                                          <label htmlFor="printQty" className="form-label">Label Qty: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="printQty"
                                            name="printQty"
                                            type="text"
                                            value={this.state.printQty}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />

                                          <label htmlFor="printSize" className="form-label">Font Size: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="printSize"
                                            name="printSize"
                                            type="text"
                                            value={this.state.printSize}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />  

                                          <label htmlFor="labelHeight" className="form-label">Label Height: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="labelHeight"
                                            name="labelHeight"
                                            type="text"
                                            value={this.state.labelHeight}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                          <label htmlFor="labelWidth" className="form-label">Label Width: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="labelWidth"
                                            name="labelWidth"
                                            type="text"
                                            value={this.state.labelWidth}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                          
                                        </div>
                                        <div className="modalMessage">{this.state.modalMessage}</div>
                                        <div>
                                          <ReactToPrint
                                            trigger={() => <Link to="#"><button type="button" className="btn btn-info btn-sm">Print</button></Link>}
                                            content={() => this.componentRef}
                                          /> &nbsp;&nbsp;
                                          <button className="btn btn-info btn-sm" onClick={this.closeLabelModal}>Cancel</button>
                                        </div> 
                                        <LocationPrintLabel ref={el => (this.componentRef = el)} labelHeight={this.state.labelHeight} labelWidth={this.state.labelWidth} printSize={this.state.printSize} printQty={this.state.printQty} printLocation={this.state.printLocation} {...this.props} />
																			
                                      </div>
                                      
                                  </div>
                                 
                              </form> 

                             </div>                          
                             </div>
													  </div>
                          </Modal>
              </div>

              <audio className="good-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/good-noise.mp3'}></source>
        </audio>

        <audio className="bad-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/bad-noise.mp3'}></source>
        </audio>

        <audio className="complete-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/complete-noise.mp3'}></source>
        </audio>

  </div>



                )
    }
}
export default ProductBinDetail;